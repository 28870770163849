export const menus = (locale: string, linkPrefix: string) => {
  return [
    {
      id: 'menulaw',
      children: [
        {
          id: 'companyterms',
          to: `${import.meta.env.VITE_FELO_ID_URL}/business/policies/terms-of-use-subtitle`,
          isALink: true,
        },
        {
          id: 'companyprivacy',
          to: `${import.meta.env.VITE_FELO_ID_URL}/business/policies/privacy-policy`,
          isALink: true,
        },
        {
          id: 'companySCTA',
          to: `${import.meta.env.VITE_FELO_ID_URL}/business/scta`,
          isALink: true,
        },
        { id: 'menusafety', to: `${import.meta.env.VITE_FELO_ID_URL}/business/security`, isALink: true },
      ],
    },
    {
      id: 'menucompany',
      children: [
        { id: 'menuabout', to: 'https://www.sparticle.com/ja' },
        {
          id: 'menuNews',
          to: 'https://www.sparticle.com/#news-target',
        },
      ],
    },
    {
      id: 'menuproduct',
      children: [
        {
          id: 'menugbase',
          to: 'https://gbase.ai/',
        },
        {
          id: 'menutranslator',
          to: (import.meta.env.VITE_TRANSLATOR_URL as string) + '/' + (locale === 'en' ? '' : locale),
        },
        {
          id: 'menumeet',
          to: (import.meta.env.VITE_FELO_MEET as string) + '/' + (locale === 'en' ? '' : locale),
        },
        {
          id: 'menusubtitle',
          to: import.meta.env.VITE_FELO_SUBTITLES as string,
        },
        {
          id: 'menucircleO',
          to: 'https://virtualoffice.circleo.me/',
        },
      ],
    },
  ];
};
